import React, { Fragment, useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { MdAvTimer, MdComment, MdPhone } from "react-icons/md"
import {
  RiFacebookCircleFill,
  RiInstagramLine,
  RiWhatsappLine,
} from "react-icons/ri"
import Logo from "../../../logo"
import SwiperSlider from "../../../UI/swiper"
import Heading from "../../../Heading"
import Text from "../../../Text"
import Social, { SocialLink } from "../../../UI/social"
import { MainMenu, MobileMenu } from "../../../menu"
import HeaderForm from "../../../forms/search-form/layout-two"
import BurgerButton from "../../../UI/burger-button"
import OffCanvas, {
  OffCanvasHeader,
  OffCanvasBody,
} from "../../../UI/off-canvas"
import {
  HeaderWrap,
  HeaderTop,
  HeaderMain,
  HeaderTopLeft,
  HeaderTopRight,
  HeaderTopRightInner,
  InfoItem,
  InfoIcon,
  InfoContent,
  HeaderBottom,
  HeaderBottomLeft,
  HeaderNavigation,
  HeaderBottomRight,
  HeaderElement,
} from "./header.style"

const Header = props => {
  const data = useStaticQuery(graphql`
    {
      prismicInformacion(id: { eq: "fbb62bd6-f80d-5b31-ba8a-91b04a10b029" }) {
        data {
          celular {
            text
          }
          celular_2 {
            text
          }
          horario_lunes_viernes {
            text
          }
          horario_sabado {
            text
          }
          telefono_fijo {
            text
          }
        }
      }
    }
  `)
  const {
    celular,
    celular_2,
    horario_lunes_viernes,
    horario_sabado,
    telefono_fijo,
  } = data.prismicInformacion.data
  const [offCanvasOpen, setOffcanvasOpen] = useState(false)
  const [totalHeaderHeight, setTotalHeaderHeight] = useState(0)
  const [sticky, setSticky] = useState(false)
  const headerRef = useRef(null)
  const fixedRef = useRef(null)
  const offCanvasHandler = () => {
    setOffcanvasOpen(prevState => !prevState)
  }

  useEffect(() => {
    setTotalHeaderHeight(headerRef.current.clientHeight)
  }, [totalHeaderHeight])

  useEffect(() => {
    const scrollHandler = () => {
      let scrollPos = window.scrollY
      if (scrollPos > totalHeaderHeight) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
    window.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [sticky, totalHeaderHeight])

  const menuArr = [
    {
      text: "Inicio",
      link: "/",
    },
    {
      text: "Nosotros",
      link: "/nosotros",
    },
    {
      text: "Productos",
      link: "/productos",
      isSubmenu: true,
      submenu: [
        {
          text: "Llantas para Construcción",
          link: "/categoria/construccion",
        },
        {
          text: "Llantas Industriales",
          link: "/categoria/montacarga",
        },
        {
          text: "Llantas para Minería",
          link: "/categoria/cargador-frontal-mineria",
        },
        {
          text: "Llantas Forestales",
          link: "/categoria/tractor-forestal",
        },
        {
          text: "Llantas Agrícolas",
          link: "/categoria/tractor-agricola",
        },
        {
          text: "Llantas para Camionetas",
          link: "/categoria/camionetas",
        },
        {
          text: "Llantas para Camiones",
          link: "/categoria/camiones",
        },
        {
          text: "Llantas para Autos",
          link: "/categoria/autos",
        },
      ],
    },
    {
      text: "Servicios",
      link: "/servicios",
    },
    {
      text: "Contacto",
      link: "/contacto",
    },
  ]

  const { slider, headerTopStyles, menuStyle } = props
  const {
    infoHeading,
    infoText,
    burgerBtnElStyle,
    innerElementStyle,
  } = headerTopStyles

  return (
    <Fragment>
      <HeaderWrap ref={headerRef} isSticky={sticky}>
        <HeaderTop>
          <Container>
            <Row>
              <Col lg={12}>
                <HeaderMain>
                  <HeaderTopLeft>
                    <Logo />
                  </HeaderTopLeft>
                  <HeaderTopRight>
                    <HeaderTopRightInner isOpen={true}>
                      <HeaderElement
                        {...innerElementStyle}
                        visibility={{ default: "false", xs: "true" }}
                      >
                        <HeaderForm inputId="header-search" />
                      </HeaderElement>
                      <HeaderElement {...innerElementStyle}>
                        <SwiperSlider
                          settings={slider}
                          className="header-top-info-slider-wrap"
                        >
                          <div className="item">
                            <InfoItem>
                              <InfoIcon>
                                <MdComment className="icon" />
                              </InfoIcon>
                              <InfoContent>
                                <Heading
                                  {...infoHeading}
                                  content="Escríbenos"
                                />
                                <a href={`tel:${celular.text}`}>
                                  <Text {...infoText} content={celular.text} />
                                </a>
                                <br />
                                <a href={`tel:${celular_2.text}`}>
                                  <Text
                                    {...infoText}
                                    content={celular_2.text}
                                  />
                                </a>
                              </InfoContent>
                            </InfoItem>
                          </div>
                          <div className="item">
                            <InfoItem>
                              <InfoIcon>
                                <MdPhone className="icon" />
                              </InfoIcon>
                              <InfoContent>
                                <Heading {...infoHeading} content="Llámanos" />
                                <a href={`tel:${telefono_fijo.text}`}>
                                  <Text
                                    {...infoText}
                                    content={telefono_fijo.text}
                                  />
                                </a>
                              </InfoContent>
                            </InfoItem>
                          </div>
                          <div className="item">
                            <InfoItem>
                              <InfoIcon>
                                <MdAvTimer className="icon" />
                              </InfoIcon>
                              <InfoContent>
                                <Heading
                                  {...infoHeading}
                                  content={horario_lunes_viernes.text}
                                />
                                <Text {...infoText} content="Lunes a Viernes" />
                              </InfoContent>
                            </InfoItem>
                          </div>
                          <div className="item">
                            <InfoItem>
                              <InfoIcon>
                                <MdAvTimer className="icon" />
                              </InfoIcon>
                              <InfoContent>
                                <Heading
                                  {...infoHeading}
                                  content={horario_sabado.text}
                                />
                                <Text {...infoText} content="Sábado" />
                              </InfoContent>
                            </InfoItem>
                          </div>
                        </SwiperSlider>
                      </HeaderElement>
                      <HeaderElement {...innerElementStyle}>
                        <Social
                          tooltip={true}
                          tooltip_position="bottom-left"
                          tooltip_bg="dark"
                          space="10px"
                          color="#fff"
                        >
                          <SocialLink
                            path="https://www.facebook.com/Seggasa.Llantas.Industriales/"
                            title="Facebook"
                          >
                            <RiFacebookCircleFill />
                          </SocialLink>
                          <SocialLink
                            path="https://www.instagram.com/seggasallantasindustriales/"
                            title="Instagram"
                          >
                            <RiInstagramLine />
                          </SocialLink>
                          <SocialLink
                            path="https://api.whatsapp.com/send?phone=+51998123024&text=Hola"
                            title="Whatsapp"
                          >
                            <RiWhatsappLine />
                          </SocialLink>
                        </Social>
                      </HeaderElement>
                    </HeaderTopRightInner>
                    <HeaderElement
                      {...burgerBtnElStyle}
                      visibility={{ default: "false", lg: "true" }}
                    >
                      <BurgerButton onClick={offCanvasHandler} />
                    </HeaderElement>
                  </HeaderTopRight>
                </HeaderMain>
              </Col>
            </Row>
          </Container>
        </HeaderTop>
        <HeaderBottom ref={fixedRef} isSticky={sticky}>
          <Container>
            <Row>
              <Col lg={12}>
                <HeaderMain>
                  <HeaderBottomLeft>
                    <HeaderNavigation>
                      <MainMenu
                        menuData={menuArr}
                        blackColor={sticky}
                        {...menuStyle}
                      />
                    </HeaderNavigation>
                  </HeaderBottomLeft>
                  <HeaderBottomRight>
                    <HeaderForm
                      layout={sticky && "white"}
                      inputId="header-search-2"
                    />
                  </HeaderBottomRight>
                </HeaderMain>
              </Col>
            </Row>
          </Container>
        </HeaderBottom>
      </HeaderWrap>
      <OffCanvas
        scrollable={true}
        isOpen={offCanvasOpen}
        onClick={offCanvasHandler}
      >
        <OffCanvasHeader onClick={offCanvasHandler}>
          <Logo darkLogo align={{ default: "flex-start" }} />
        </OffCanvasHeader>
        <OffCanvasBody>
          <MobileMenu menuData={menuArr} />
          <a href={`tel:${telefono_fijo.text}`}>
            <Text {...infoText} content={telefono_fijo.text} />
          </a>
          <a href={`tel:${celular.text}`}>
            <Text {...infoText} content={celular.text} />
          </a>
          <a href={`tel:${celular_2.text}`}>
            <Text {...infoText} content={celular_2.text} />
          </a>
        </OffCanvasBody>
      </OffCanvas>
    </Fragment>
  )
}

Header.propTypes = {
  slider: PropTypes.object,
}

Header.defaultProps = {
  slider: {
    slidesPerView: 3,
    loop: true,
    pagination: false,
    spaceBetween: 0,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  },
  headerTopStyles: {
    infoHeading: {
      color: "#fff",
      fontSize: "14px",
      fontweight: 700,
      lineHeight: 1.18,
      mb: "6px",
    },
    infoText: {
      fontSize: "14px",
    },
    burgerBtnElStyle: {
      pl: "40px",
    },
    clickAbleElStyle: {
      pl: "15px",
    },
    clickAble: {
      fontsize: "30px",
      color: "#6D70A6",
    },
    innerElementStyle: {
      responsive: {
        small: {
          width: "100%",
        },
      },
    },
  },
  menuStyle: {
    alignment: "left",
    color: "#000",
    hoverColor: "#ccc",
    barColor: "#000",
    vSpace: 42,
    submenu: {
      hoverColor: "primary",
    },
  },
}

export default Header
