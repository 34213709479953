import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { SiFacebook, SiInstagram, SiWhatsapp } from "react-icons/si"
import { Container } from "react-bootstrap"
import { Row, Col } from "../../../UI/wrapper"
import Text from "../../../Text"
import Anchor from "../../../UI/anchor"
import Heading from "../../../Heading"
import Img from "gatsby-image"
import Social, { SocialLink } from "../../../UI/social"
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterWidgetList,
  FooterBottom,
} from "./footer.style"

const Footer = ({ copyrightStyle, ...props }) => {
  const data = useStaticQuery(graphql`
    {
      prismicInformacion(id: { eq: "fbb62bd6-f80d-5b31-ba8a-91b04a10b029" }) {
        data {
          celular {
            text
          }
          direccion {
            text
          }
          email {
            text
          }
        }
      }
      file(relativePath: { eq: "logo-seggasa.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const { celular, direccion, email } = data.prismicInformacion.data
  return (
    <FooterWrap {...props}>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                <LogoWidget>
                  <Img
                    fixed={data.file.childImageSharp.fixed}
                    alt="Logo Seggasa"
                  />
                </LogoWidget>
                <TextWidget>
                  <Text mb="10px" content={`${direccion.text}, Lima.`} />

                  <Text
                    mb="10px"
                    content={
                      <Anchor
                        path={email.text}
                        color="textColor"
                        hoverstyle="2"
                      >
                        {email.text}
                      </Anchor>
                    }
                  />

                  <Text
                    mb="10px"
                    content={
                      <Anchor
                        path={`tel:${celular.text}`}
                        fontWeight="800"
                        color="#333"
                        hoverstyle="2"
                      >
                        {celular.text}
                      </Anchor>
                    }
                  />
                  <Text
                    mb="10px"
                    content={
                      <Anchor path="www.seggasa.com" hoverstyle="2">
                        www.seggasa.com
                      </Anchor>
                    }
                  />
                </TextWidget>
              </FooterWidget>
            </Col>
            <Col lg={4} md={6} sm={8}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                <Heading
                  as="h6"
                  mt="-3px"
                  mb="20px"
                  color="white"
                  content="Accesos Rápidos"
                />
                <FooterWidgetList>
                  <li>
                    <Anchor path="/nosotros" color="textColor" hoverstyle="2">
                      Nosotros
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/productos" color="textColor" hoverstyle="2">
                      Productos
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/servicios" color="textColor" hoverstyle="2">
                      Servicios
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/contacto" color="textColor" hoverstyle="2">
                      Contacto
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={4} md={6} sm={8}>
              <FooterWidget responsive={{ medium: { mb: "27px" } }}>
                <Heading
                  as="h6"
                  mt="-3px"
                  mb="20px"
                  color="white"
                  content="Ayuda"
                />
                <FooterWidgetList>
                  <li>
                    <Anchor path="/preguntas" color="textColor" hoverstyle="2">
                      Preguntas y Recomendaciones
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/consejos" color="textColor" hoverstyle="2">
                      Consejos
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/politicas-de-privacidad"
                      color="textColor"
                      hoverstyle="2"
                    >
                      Políticas de Privacidad
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/terminos-y-condiciones"
                      color="textColor"
                      hoverstyle="2"
                    >
                      Términos y Condiciones
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              <Text
                {...copyrightStyle}
                content={`
                \u00a9 ${new Date().getFullYear()} Todos los derechos reservados.`}
              />
            </Col>
            <Col md={6} className="text-center text-md-right">
              <Social
                space="8px"
                tooltip={true}
                shape="rounded"
                varient="outlined"
              >
                <SocialLink
                  path="https://www.facebook.com/Seggasa.Llantas.Industriales/"
                  title="Facebook"
                >
                  <SiFacebook />
                </SocialLink>
                <SocialLink
                  path="https://www.instagram.com/seggasallantasindustriales/"
                  title="Instagram"
                >
                  <SiInstagram />
                </SocialLink>
                <SocialLink
                  path={`https://api.whatsapp.com/send?phone=${celular.text}&text=Hola`}
                  title="Whatsapp"
                >
                  <SiWhatsapp />
                </SocialLink>
              </Social>
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  )
}

Footer.propTypes = {
  bgcolor: PropTypes.string,
  reveal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Footer.defaultProps = {
  bgcolor: "#000",
  reveal: "false",
  copyrightStyle: {
    responsive: {
      small: {
        pb: "15px",
      },
    },
  },
}

export default Footer
