import React, { useState } from "react"
import { navigate } from "gatsby"
import { MdSearch } from "react-icons/md"
import { HeaderFormWrap, ButtonWrap } from "./search-form.style"
import Form, { Input } from "../../../UI/form"
import Button from "../../../UI/button"

const HeaderForm = ({ input, inputId, ...restProps }) => {
  const [value, setValue] = useState()
  const handleInput = e => setValue(e.target.value)

  const handlSubmit = e => {
    e.preventDefault()

    const query = value
      .toLowerCase()
      .trim()
      .replace(/[^\w ]/g, "")
    // .replace(/\&+/g, '-')

    navigate(`/buscador?busqueda=${query}`, { state: { query } })
  }

  return (
    <HeaderFormWrap {...restProps}>
      <Form onSubmit={handlSubmit}>
        <Input
          onChange={handleInput}
          type="text"
          placeholder="Buscador..."
          hover="false"
          id={inputId}
          {...input}
        />
        <ButtonWrap>
          <Button
            type="submit"
            skin="transparent"
            hover="false"
            varient="onlyIcon"
            color="#000"
          >
            <MdSearch />
          </Button>
        </ButtonWrap>
      </Form>
    </HeaderFormWrap>
  )
}

HeaderForm.defaultProps = {
  input: {
    bgcolor: "#efefef",
    borderradius: "0",
    bordercolor: "transparent",
    color: "#000",
    pl: "10px",
    pr: "50px",
    height: "60px",
  },
}

export default HeaderForm
