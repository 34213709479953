import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const LinkStyled = css`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 41px;
  letter-spacing: 2px;
  display: block;
  .icon {
    font-size: 26px;
  }

  position: relative;
  &:before {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    content: "";
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 0;
    height: 1px;

    background-color: rgb(246, 242, 237);
  }
  &:hover:before {
    left: 0;
    width: 100%;
    right: auto;
  }

  &.image-btn {
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
    overflow: hidden;
  }
`

export const LinkWrapper = styled(Link)`
  ${LinkStyled}
`

export const AnchorTag = styled.a`
  ${LinkStyled}
`
