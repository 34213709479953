import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { LogoWrapper } from "./logo.style"

const Logo = () => {
  const Logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-seggasa.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <LogoWrapper>
      <Link to="/">
        <Img fixed={Logo.file.childImageSharp.fixed} alt="Logo Seggasa" />
      </Link>
    </LogoWrapper>
  )
}

Logo.propTypes = {
  justifycontent: PropTypes.string,
}

Logo.defaultProps = {
  justifycontent: "flex-start",
}

export default Logo
