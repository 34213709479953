import styled, { css } from "styled-components"
import { device } from "../../../theme"

export const LinkWrapper = styled.a`
  position: fixed;
  width: 64px;
  height: 64px;
  bottom: 152px;
  right: 12px;
  z-index: 100;

  .whatsapp-icon {
    margin-top: 16px;
  }
  ${props =>
    props.responsive &&
    css`
      @media ${device.medium} {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        .whatsapp-icon {
          margin-top: 10px;
        }
      }
    `}
`
