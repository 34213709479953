import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { LinkWrapper } from "./sticky-whatsapp.style"

const StickyWhatsApp = ({ link, ...props }) => {
  const stickyData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "icons/whatsapp.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prismicInformacion(id: { eq: "fbb62bd6-f80d-5b31-ba8a-91b04a10b029" }) {
        data {
          celular {
            text
          }
        }
      }
    }
  `)
  const whatsappIcon = stickyData.file.childImageSharp.fixed
  const numero = stickyData.prismicInformacion.data.celular.text
    .split(" ")
    .join("")

  return (
    <LinkWrapper
      href={`https://api.whatsapp.com/send?phone=${numero}&text=Buenos%20d%C3%ADas`}
      {...props}
    >
      <Img fixed={whatsappIcon} alt="Whatsapp" />
    </LinkWrapper>
  )
}

export default StickyWhatsApp
