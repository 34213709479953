// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscador-js": () => import("./../../../src/pages/buscador.js" /* webpackChunkName: "component---src-pages-buscador-js" */),
  "component---src-pages-consejos-js": () => import("./../../../src/pages/consejos.js" /* webpackChunkName: "component---src-pages-consejos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politicas-de-privacidad-js": () => import("./../../../src/pages/politicas-de-privacidad.js" /* webpackChunkName: "component---src-pages-politicas-de-privacidad-js" */),
  "component---src-pages-preguntas-js": () => import("./../../../src/pages/preguntas.js" /* webpackChunkName: "component---src-pages-preguntas-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-categoria-js": () => import("./../../../src/templates/categoria.js" /* webpackChunkName: "component---src-templates-categoria-js" */),
  "component---src-templates-lista-de-productos-js": () => import("./../../../src/templates/lista-de-productos.js" /* webpackChunkName: "component---src-templates-lista-de-productos-js" */),
  "component---src-templates-producto-js": () => import("./../../../src/templates/producto.js" /* webpackChunkName: "component---src-templates-producto-js" */)
}

