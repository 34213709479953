import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { theme } from "../theme"
import "../assets/fonts/cerebrisans/cerebrisans.css"
import "../assets/css/bootstrap.css"
import { GlobalStyle } from "../assets/css/main-style"
import ScrollToTop from "./UI/scroll-to-top"
import Transition from "./transition"
import StickyWhatsApp from "./UI/sticky-whatsapp"
import Footer from "./layout/footer/footer-one"
import Header from "./layout/header/header-two"

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider theme={theme}>
      <Transition location={location}>
        <div className="wrapper">
          <GlobalStyle />
          <Header />
          {children}
          <StickyWhatsApp />
          <Footer />
          <ScrollToTop />
        </div>
      </Transition>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
